body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 
.antd-pro-layouts-user-layout-logo {
  height: 44px;
  width: auto;
  margin-right: 16px;
  vertical-align: top;
  background-image: url(https://www.scgpackaging.com/public/frontend/assets/img/icon/logo__scg.svg);
  background-repeat: no-repeat;
}

.antd-pro-layouts-user-layout-title {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
}

.antd-pro-pages-user-login-style-main {
  /* width: 368px; */
  padding:0 20px;
  margin: 0 auto;
  margin-top: 40px;
}

.ant-input{
  padding: 8px 11px;
  border-radius: 20px;
}

.ant-form-item-label > label {
  height:42px;
}

.ant-btn-default{
  background-color: #ff2600;
  border-color: #ff2600;
  color: #FFFFFF;
}

.ant-btn{
  min-width:125px;
  padding:8px 10px;
  font-weight: 600;
  height:auto;
  font-size: 14px;
  border-radius:20px;
  /* border:1px solid #ff2600 */
}

.language-text{
  color: #000;
}

.language-text.active {
 color: #000;
 font-weight:bold;
 text-decoration: underline
}

.language-text{
  color: #000;
}

.cursor-pointer {
  cursor: pointer;
}

.scg-background{
  background-image: url("./assets/icons/SCGP-2020-Login-BG.jpg");
  height: 100%;
  width:100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 300px;
}

.ant-form-item-explain.ant-form-item-explain-error{
  margin: 10px 0px;
}

.register-link{
  margin-left: 20px;
    color: #ff2600;
    text-decoration: underline;
}

.login-link{
  margin-left: 20px;
  color: #ff2600;
  text-decoration: underline;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  display:none;
}